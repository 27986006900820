export default {
  data: () => ({
    rules: {
      required: v => !!v || '入力必須です。',
      maxMemoLen: v => v.length <= 300 || '300文字以内で入力してください。',
      zeroOrMore: v => /^([0-9]|[1-9][0-9]+)$/.test(v) || '0以上の数値を入力してください。',
      oneOrMore: v => /^([1-9]|[1-9][0-9]+)$/.test(v) || '1以上の数値を入力してください。',
      // cliString: v => /^[a-zA-Z0-9!-/:->@[-`{-~]*$/.test(v) || '使用できない文字が含まれています。',
      cliWord: v => /^[a-zA-Z0-9#-&(-/:;=@[\]-_{}~]*$/.test(v) || '使用できない文字が含まれています。',
      clientidLen: v => v.length <= 64 || '64文字以内で入力してください。',
      uidLen: v => v.length <= 64 || '64文字以内で入力してください。',
      passwordLen: v => v.length <= 32 || '32文字以内で入力してください。',
      inputPasswordLen: v => (v.length >= 10 && v.length <= 32) || '10文字以上32文字以内で入力してください。',
      emailFormat: [
        v => !!v || '入力必須です。',
        v => v.length <= 256 || 'メールアドレスを入力してください。',
        v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'メールアドレスの形式が正しくありません。',
      ],
      selecterReq: v => !!v || '選択必須です。',
      // validUrl: v => /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.[a-z]{2,})|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/.test(v) || 'URLの形式が正しくありません。',
      validUrl: v => /^https?:\/\/[\w/:%#$&?()~.=+-]+$/.test(v) || 'URLの形式が正しくありません。',
    },
  }),
}
