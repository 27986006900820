<template>
  <v-container
    class="ma-0 pa-0 fill-height"
    fluid
    style="background-color: #24343c;"
  >
    <loading-overlay
      :on-off="loading"
    />
    <v-container
      class="my-16"
      fluid
      style="max-width: 1000px;"
    >
      <v-card-title class="d-flex justify-center">
        <v-icon
          size="80"
          color="success"
        >
          mdi-certificate-outline
        </v-icon>
      </v-card-title>
      <v-card-title class="mb-4 text-h2 font-weight-bold d-flex justify-center white--text">
        資格管理アプリ
      </v-card-title>
      <v-card-text class="my-8 text-h3 d-flex justify-center grey--text">
        「資格管理アプリ」は、取得済み・勉強中の資格をラクラク管理するためのWebアプリです。エクセルでの資格管理とはサヨナラしましょう。
         ログインすると資格情報を保存することができます。
      </v-card-text>
      <v-row class="ma-0 pa-0">
        <v-col md="6">
          <v-btn
            class="text-h4 text-none"
            height="42"
            block
            color="white"
            @click="googleLogin()"
          >
            <v-list-item-avatar>
              <v-avatar
                class="ma-0"
                size="30"
              >
                <v-img
                  src="https://img.freepik.com/free-icon/search_318-265146.jpg"
                  alt="Google Icon"
                />
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Googleアカウントでログイン
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </v-col>
        <v-col md="6">
          <v-btn
            class="text-h4 text-none"
            height="42"
            block
            color="white"
            @click="trialLogin()"
          >
            <v-list-item-content>
              <v-list-item-title>
                ログインせずにおためし利用する
              </v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </v-col>
      </v-row>
      <div class="text-center mt-10">
        <v-btn
          color="info"
          text
          large
          target="_blank"
          href="https://note.com/shikakukanri/n/n3e5c02c2ad97"
        >
          資格管理アプリとは？
        </v-btn>
      </div>
      <div class="text-center">
        <v-btn
          color="info"
          text
          target="_blank"
          href="https://note.com/shikakukanri/n/nb011f96a4948"
        >
          Privacy
        </v-btn>
        <v-btn
          color="info"
          text
          target="_blank"
          href="https://note.com/shikakukanri/n/n5aa509667998"
        >
          Terms
        </v-btn>
        <v-btn
          color="info"
          text
          target="_blank"
          href="https://note.com/shikakukanri/n/n7ce1be0171e1"
        >
          Release
        </v-btn>
      </div>
    </v-container>
  </v-container>
</template>

<script>
  import firebase from 'firebase'
  import rulesMixin from './mixin/rules'
  import commonMixin from './mixin/common'
  import {
    funcGetDataFromFirebase,
  } from '../../functions/fb-modules'
  import { funcStoredUserInformation } from '../../functions/utils'
  export default {
    components: {
      LoadingOverlay: () => import('./components/LoadingOverlay'),
    },
    mixins: [
      commonMixin,
      rulesMixin,
    ],
    data: () => ({
      loading: false,
    }),
    created () {
      /*
      setTimeout(() => {
        this.loading = false
      }, 2000)
      */
    },
    methods: {
      trialLogin () {
        this.loading = true
        const login = true
        const uid = ''
        const displayName = 'トライアル'
        const photoURL = 'https://kotonohaworks.com/free-icons/wp-content/uploads/kkrn_icon_user_2.png'
        const data = this.createSampleData()
        const shikakuList = data.shikakuList
        const studyList = data.studyList
        const kininaruList = data.kininaruList
        const taskList = data.taskList
        const darkMode = false
        funcStoredUserInformation(login, uid, displayName, photoURL, shikakuList, studyList, kininaruList, taskList, darkMode)
        this.loading = false
        this.$router.push({ path: '/' })
      },
      googleLogin () {
        var provider = new firebase.auth.GoogleAuthProvider()
        provider.setCustomParameters({ prompt: 'select_account' })
        firebase.auth()
          .signInWithPopup(provider)
          .then((result) => {
            /* Googleアカウントのデータを取得 */
            this.loading = true
            var user = result.user
            /* Firebaseのデータを取得 */
            funcGetDataFromFirebase(user.uid)
              .then((resultFb) => {
                /* GoogleアカウントとFBのデータを突合 */
                const login = true
                const uid = user.uid
                const displayName = 'Googleアカウント'
                const photoURL = 'https://img.freepik.com/free-icon/search_318-265146.jpg'
                var shikakuList = []
                var studyList = []
                var kininaruList = []
                var taskList = []
                var darkMode = false
                if (Object.keys(resultFb).length) {
                  if (Object.prototype.hasOwnProperty.call(resultFb, 'shikakuList')) {
                    shikakuList = resultFb.shikakuList
                  }
                  if (Object.prototype.hasOwnProperty.call(resultFb, 'studyList')) {
                    studyList = resultFb.studyList
                  }
                  if (Object.prototype.hasOwnProperty.call(resultFb, 'kininaruList')) {
                    kininaruList = resultFb.kininaruList
                  }
                  if (Object.prototype.hasOwnProperty.call(resultFb, 'taskList')) {
                    taskList = resultFb.taskList
                  }
                  if (Object.prototype.hasOwnProperty.call(resultFb, 'darkMode')) {
                    darkMode = resultFb.darkMode
                  }
                }
                funcStoredUserInformation(login, uid, displayName, photoURL, shikakuList, studyList, kininaruList, taskList, darkMode)
                this.$router.push({ path: '/' })
                this.loading = false
              }).catch((error) => {
                console.error('Failed to get data from firebase', error)
                this.loading = false
              })
          }).catch((error) => {
            console.error('Login failed.', error)
            this.loading = false
          })
      },
    },
  }
</script>
